import javaScriptImg from "../assets/img/JavaScript.png";
import javaImg from "../assets/img/Java.png";
import reactImg from "../assets/img/React.png";
import nodeImg from "../assets/img/Node.png";
import mySqlImg from "../assets/img/MySql.png";
import apiImg from "../assets/img/API.png";
import pythonImg from "../assets/img/Python.png";
import cImag from "../assets/img/C.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>
                          Here are some of the programming languages I've worked with that<br/>
                          have helped me complete tasks at work and build personal<br/>
                          projects as a web and software developer
                        </p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={reactImg} alt="Image" />
                                <h5>React JS</h5>
                            </div>
                            <div className="item">
                                <img src={nodeImg} alt="Image" />
                                <h5>Node JS</h5>
                            </div>
                            <div className="item">
                                <img src={javaImg} alt="Image" />
                                <h5>Java</h5>
                            </div>
                            <div className="item">
                                <img src={javaScriptImg} alt="Image" />
                                <h5>JavaScript</h5>
                            </div>
                            <div className="item">
                                <img src={apiImg} alt="Image" />
                                <h5>RESTful API</h5>
                            </div>
                            <div className="item">
                                <img src={mySqlImg} alt="Image" />
                                <h5>MySQL</h5>
                            </div>
                            <div className="item">
                                <img src={pythonImg} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={cImag} alt="Image" />
                                <h5>C# .NET</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
